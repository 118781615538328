import { container, title } from 'assets/jss/material-kit-react';

const articlePageStyle = theme => ({
  container: {
    zIndex: '12',
    color: '#FFFFFF',
    ...container,
    textAlign: 'center',
  },
  title: {
    ...title,
    display: 'inline-block',
    position: 'relative',
    marginTop: '30px',
    minHeight: '32px',
    color: '#FFFFFF',
    textDecoration: 'none',
    textShadow: '2px 0 10px black',
    fontFamily: 'Great Vibes',
  },
  subtitle: {
    fontSize: '1.313rem',
    maxWidth: '500px',
    margin: '10px auto 0',
  },
  main: {
    background: '#FFFFFF',
    position: 'relative',
    zIndex: '3',
  },
  mainRaised: {
    margin: '-60px 30px 0px',
    borderRadius: '6px',
    boxShadow:
      '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('xs')]: {
      margin: '-40px 0px 0px',
    },
  },
  section: {
    padding: '70px 0',
    textAlign: 'center',
  },
  content: {
    color: '#000',
  },
});

export default articlePageStyle;
