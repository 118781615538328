import React from 'react';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from 'utils/htmlSerializer';

const linkResolver = require('utils/linkResolver');

export default ({ slice }) => (
  <div className="post-text container">
    <div>{RichText.render(slice.primary.text.raw, linkResolver, htmlSerializer)}</div>
  </div>
);
