import React from 'react';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import { Link } from 'react-scroll';
// core components
import Layout from 'components/Layout';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Parallax from 'components/Parallax/Parallax';
import SEO from 'components/SEO';
import { ImageCaption, Quote, Text } from 'components/Slices';

import articlePageStyle from 'assets/jss/material-kit-react/views/articlePage';

// Query for the News Article content in Prismic
export const query = graphql`
  query NewsArticleQuery($uid: String!, $lang: String = "fr-fr") {
    prismicNewsArticle(lang: { eq: $lang }, uid: { eq: $uid }) {
      lang
      type
      uid
      url
      alternate_languages {
        lang
        type
        uid
      }
      data {
        body {
          ... on PrismicNewsArticleDataBodyImageWithCaption {
            id
            primary {
              caption {
                raw
              }
              image {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicNewsArticleDataBodyQuote {
            id
            primary {
              quote {
                raw
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicNewsArticleDataBodyText {
            id
            primary {
              text {
                raw
              }
            }
            slice_label
            slice_type
          }
        }
        excerpt {
          raw
        }
        hero_image {
          dimensions {
            height
            width
          }
          url
        }
        title {
          raw
        }
      }
      lang
    }
    prismicHomepage(lang: { eq: $lang }) {
      data {
        tours {
          destination {
            document {
              ... on PrismicDestination {
                id
                lang
                type
                uid
                url
                data {
                  name {
                    raw
                  }
                }
              }
            }
          }
          message {
            raw
          }
        }
      }
    }
    prismicMenu(lang: { eq: $lang }) {
      data {
        links {
          label {
            raw
          }
          link {
            lang
            type
            url
          }
        }
      }
    }
  }
`;

// Sort and display the different slice options
const PostSlices = ({ slices }) => {
  return slices.map(slice => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'text':
          return (
            <div key={slice.slice_type} className="homepage-slice-wrapper">
              {<Text slice={slice} />}
            </div>
          );

        case 'quote':
          return (
            <div key={slice.slice_type} className="homepage-slice-wrapper">
              {<Quote slice={slice} />}
            </div>
          );

        case 'image_with_caption':
          return (
            <div key={slice.slice_type} className="homepage-slice-wrapper">
              {<ImageCaption slice={slice} />}
            </div>
          );

        default:
          return null;
      }
    })();
    return res;
  });
};

const ArticleTemplate = ({ data, classes }) => {
  if (!data) return null;

  const article = data.prismicNewsArticle;
  const { lang } = article;
  const alternateLanguages = article.alternate_languages;

  const activeDocMeta = { lang, alternateLanguages };
  const title = article.data.title ? RichText.asText(article.data.title.raw) : '';
  const body = article.data.body ? article.data.body : null;
  const heroImage = article.data.hero_image ? article.data.hero_image : null;
  const date = article.data.date ? article.data.date : '';
  const excerpt = article.data.excerpt
    ? RichText.asText(article.data.excerpt.raw)
    : RichText.asText(article.data.title.raw);

  const { tours } = data.prismicHomepage.data;

  const menu = data.prismicMenu.data;
  if (!menu) return null;

  return (
    <Layout activeDocMeta={activeDocMeta} menu={menu} tours={tours}>
      <SEO
        title={`${title} | Servitours`}
        description={excerpt}
        image={heroImage}
        publishDate={date}
      />
      <Parallax filter image={heroImage.url}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12}>
              <h1 className={classes.title}>{title}</h1>
              <br />
            </GridItem>
            <GridItem xs={12}>
              <div id="scrollDownButton" className="flex-center">
                <Link
                  className="js-scroll-trigger"
                  to="content"
                  spy
                  smooth
                  offset={-70}
                  duration={500}
                >
                  <span className="blue-text" />
                  <span className="white-text" />
                  <span className="red-text" />
                </Link>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <div className={classes.section}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={12} md={8} id="content" className={classes.content}>
                {body ? <PostSlices slices={body} /> : null}
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withStyles(articlePageStyle)(ArticleTemplate);
